import moment from "moment";
import { decodeSanitizedInputValue } from "../utils/coursesHelper";

export const STAT_TAB_PLATFORM = "by_platform";
export const STAT_TAB_COURSES = "by_courses";
export const STAT_TAB_SCORE = "by_grade";
export const STAT_TAB_COMPANY = "by_company";
export const STAT_TAB_TEAM = "by_team";
export const STAT_TAB_INTEGRUM = "by_integrum";
export const STAT_TAB_USERS = "by_users";

export const COURSE_PASSING_COLORS = [{
   name: "Completed",
   color: "#44DD65"
},
{
   name: "Ongoing",
   color: "#0B88F8"
},
{
   name: "Failed",
   color: "#FF5959"
},
{
   name: "Not started",
   color: "#3E3E41"
},
]

export const DEFAULT_DEBOUNCE_DELAY = 700;

export const STATISTICS_TABS = [
   { id: 1, label: STAT_TAB_PLATFORM, name: "By Platform" },
   { id: 2, label: STAT_TAB_COURSES, name: "By Courses" },
   { id: 3, label: STAT_TAB_SCORE, name: "By Score" },
   { id: 4, label: STAT_TAB_COMPANY, name: "By Company" },
   { id: 5, label: STAT_TAB_USERS, name: "By User" },
   { id: 6, label: STAT_TAB_INTEGRUM, name: "Integration" },
   // { id: 5, label: STAT_TAB_TEAM, name: "By Team" },
];

export const DATE_TAB_DAYLY = "daily";
export const DATE_TAB_WEEKLY = "weekly";
export const DATE_TAB_MONTHLY = "monthly";
export const DATE_TAB_YEARLY = "yearly";

export const DATE_TABS = [
   { id: 1, label: DATE_TAB_DAYLY, name: "Daily" },
   { id: 2, label: DATE_TAB_WEEKLY, name: "Weekly" },
   { id: 3, label: DATE_TAB_MONTHLY, name: "Monthly" },
   { id: 4, label: DATE_TAB_YEARLY, name: "Yearly" },
];

export const DAILY_VISIBLE_LABELS = [
   "00:00",
   "6:00",
   "10:00",
   "14:00",
   "19:00",
   "23:00",
];

export const MONTHLY_VISIBLE_LABELS = [
   "1",
   "6",
   "12",
   "18",
   "24",
   "31",
];

export const TESTS_TABLE_HEADERS = [
   'test_name',
   "pass",
   "on_rework",
   "failed",
 ];

export const QUESTIONS_TABLE_HEADERS = [
   'question_name',
   "true",
   "partially",
   "false",
 ];

export const COURSES_TABLE_HEADERS = [
   "rating",
   'course_name',
   "created",
   "last_modified",
   "available",
   "company",
   "course_score",
   "rating_from_users",
 ];

export const COURSES_PROGRESS_TABLE_HEADERS = [
   'course_name',
   "available",
   "not_started",
   "ongoing",
   "completed",
   "failed",
 ];

export const STATISTICS_USER_COURSES_TABLE_HEADERS = [
   'course_name',
   "user_score",
   "progress",
   "available_from",
   "first_visit",
 ];

export const RATING_TABLE_HEADERS = [
   'company_name',
   'team_name',
   "user",
   "user_score",
 ];

export const INTEGRATION_TABLE_HEADERS = [
   "user",
   'team_name',
   'company',
   "assigned_courses",
   "completed_courses",
   "integration",
 ];

export const STATISTICS_USERS_TABLE_HEADERS = [
   'user',
   'team_name',
   'courses',
   "user_score",
   "integration",
   "registration",
   "first_online",
   "last_online",
 ];

export const STATISTICS_USERS_TABLE_HEADERS_WITH_COURSE_FILTER = [
   'user',
   'team_name',
   'courses',
   "user_score",
   "progress",
   "integration",
   "available_from",
   "first_visit",
 ];

export const CERTIFICATES_TABLE_HEADERS = [
   'certificate_id',
   "course",
   "company_name",
   "student_amount",
   "percentage",
 ];

export const generateMinuteLabels = () => {
   const allLabels = [];
   const minutesPerStep = 15;

   for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += minutesPerStep) {
         const formattedHour = hour.toString().padStart(2, "0");
         const formattedMinute = minute.toString().padStart(2, "0");
         const label = `${formattedHour}:${formattedMinute}`;
         allLabels.push(label);
      }
   }
   return allLabels;
};

export const generateWeeklyLabels = () => {
   const allLabels = [];
   const daysPerStep = 1;

   for (let day = 0; day < 7; day += daysPerStep) {
      const formattedDay = moment()
         .startOf("week")
         .add(day, "days")
         .format("dddd");
      allLabels.push(formattedDay);
   }

   return allLabels;
};

export const generateMonthlyLabels = () => {
   const allLabels = [];
   const daysInMonth = moment().daysInMonth();

   for (let day = 1; day <= daysInMonth; day++) {
      allLabels.push(day);
   }

   return allLabels;
};

const monthNames = [
   "January", "February", "March", "April", "May", "June",
   "July", "August", "September", "October", "November", "December"
];

export const generateYearlyLabels = () => {
   const allLabels = [];
   const monthsInYear = 12;
   const currentDate = new Date();
   const currentYear = currentDate.getFullYear();

   for (let month = 0; month < monthsInYear; month++) {
       const monthLabel = `${monthNames[month]} ${currentYear}`;
       allLabels.push(monthLabel);

       // Move to the previous month
       currentDate.setMonth(currentDate.getMonth() - 1);
   }

   return allLabels;
};

 export const generateYearlyVisibleLabels = () => {
   const allLabels = [];
   const monthsInYear = 12;
 
   for (let month = 1; month <= monthsInYear; month++) {
     allLabels.push(`${month}`);
   }
 
   return allLabels;
 };

export function getDailyData(passedData, isGreen) {
   const currentDate = new Date();
   const currentHour = currentDate.getHours();
   const dailyData = [];

   const graphData = isGreen ? passedData?.activeUsers?.perDay?.dailyUserGrowthPer15Minutes : passedData?.userGrowth?.dailyUserGrowthPer15Minutes;

   if (!graphData) return;

   const data = graphData;
   let counter = 0;
  
   const day = currentDate.getDate().toString().padStart(2, '0');
   const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
   const year = currentDate.getFullYear();
   
   for (let i = 0; i < 24 * 60 / 15; i++) {
       const hour = Math.floor(i * 15 / 60); // Calculate the hour
       const minute = (i * 15) % 60; // Calculate the minute

       const timeKey = `${hour.toString().padStart(2, '0')}:${minute.toString().replace(/^0{2}/, '0')}`;

       const hourData = data?.[year]?.[month]?.[day];

       if (hourData && hourData[timeKey]) {
          if(isGreen) {
               dailyData.push(hourData[timeKey]);
               counter = hourData[timeKey];
           } else {
               dailyData.push(counter + 1);
               counter ++
           }
       } else {
            dailyData.push(0);
       }

       currentDate.setMinutes(currentDate.getMinutes() - 15);
   }

   return dailyData; 
}

export function getYearData(passedData, isGreen) {
   const graphData = isGreen ? passedData?.activeUsers?.perYear?.dailyUserGrowthPer15Minutes : passedData?.userGrowth?.dailyUserGrowthPer15Minutes;

   if (!graphData) return;

   const monthlyCounts = [];
   const data = graphData;
   const currentYear = new Date().getFullYear();

   let counter = 0;
 
   for (let month = 1; month <= 12; month++) {
     const monthKey = month.toString().padStart(2, '0'); // Convert to "01", "02", ..., "12"
     const monthData = data[currentYear] || {}
     const monthItems = monthData[monthKey] || {};
 
     // Calculate the count of items for the current month
     const itemCount = Object.values(monthItems)
       .map((dayItems) => Object.keys(dayItems).length)
       .reduce((total, count) => total + count, 0);

       const lastMonthArray = Object.values(monthItems)
       const lastDayArray = lastMonthArray?.length ? Object.values(lastMonthArray[lastMonthArray?.length - 1]) : {}
       const lastValue = lastDayArray?.length ? lastDayArray[lastDayArray?.length - 1] : 0

     // Add the count to the array
     monthlyCounts.push(isGreen ? lastValue : itemCount);
   }
 
   return monthlyCounts;
}

export function getDaysInMonthData(passedData, isGreen) {
   const currentDate = new Date();
   const dailyData = [];

   const graphData = isGreen ? passedData?.activeUsers?.perMonth?.dailyUserGrowthPer15Minutes : passedData?.userGrowth?.dailyUserGrowthPer15Minutes;

   if (!graphData) return [];

   const data = graphData;

   let counter = 0;

   const currentMonth = currentDate.getMonth() + 1;
   const currentYear = currentDate.getFullYear();
   const currentDay = currentDate.getDate();
   const daysInMonth = new Date(currentYear, currentMonth, 0).getDate(); 

   for (let day = 1; day <= daysInMonth; day++) {
     const dayKey = day.toString().padStart(2, '0'); 
     const monthKey = currentMonth.toString().padStart(2, '0'); 
     const year = currentYear;
     const dayData = data[year]?.[monthKey]?.[dayKey] || {};

     if (Object.keys(dayData).length > 0) {
         if (isGreen) {
            const modifyData = Object.values(dayData);
            dailyData.push(modifyData?.[modifyData?.length - 1]);
         } else {
            dailyData.push(Object.keys(dayData).length);
            counter = Object.keys(dayData).length;
         }
      } else {
         dailyData.push(day < currentDay ? counter : 0);
      }
   }

   return dailyData;
}

export function getDaysInCurrentWeekData(passedData, isGreen) {
   const currentDate = new Date();
   const currentDay = currentDate.getDay(); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday
   const dailyData = [];

   const graphData = isGreen ? passedData?.activeUsers?.perWeek?.dailyUserGrowthPer15Minutes : passedData?.userGrowth?.dailyUserGrowthPer15Minutes;

   if (!graphData) return [];

   const data = graphData;

   let counter = 0;

   const currentMonth = currentDate.getMonth() + 1;
   const currentYear = currentDate.getFullYear();

   // Calculate the start and end day of the current week
   const startDay = currentDay > 0 ? currentDay  : 6; // Adjust for Sunday
   const endDay = (startDay + 6) % 7; // End on Sunday

   for (let i = 0; i < 7; i++) {
     const day = (currentDate.getDate() - startDay + i).toString().padStart(2, '0');
     const monthKey = currentMonth.toString().padStart(2, '0');
     const year = currentYear;

     const dayData = data[year]?.[monthKey]?.[day] || {};
      if (Object.keys(dayData).length > 0) {
            if (isGreen) {
               const modifyData = Object.values(dayData);
               dailyData.push(modifyData?.[modifyData?.length - 1]);
            } else {
               dailyData.push(Object.keys(dayData).length);
               counter = Object.keys(dayData).length;
            }
      } else {
            dailyData.push(0);
      }
   }

   return dailyData;
}

export const questionsMap = (test, idx) => ({
   question_name: test?.questionContent?.includes('encoded') ? decodeSanitizedInputValue(test?.questionContent) : test?.questionContent,
   true: test?.approvedAnswerCount, 
   partially: test?.partiallyApprovedAnswerCount,
   false: test?.incorrectAnswerCount
});

export const testsMap = (test, idx) => ({
   test_name: test?.lessonName,
   pass: test?.approvedAnswerCount, 
   on_rework: test?.partiallyApprovedAnswerCount,
   failed: test?.incorrectAnswerCount
});

export const coursesMap = (course, idx) => ({
   rating: course?.averageRating || '-',
   course_score: Math.round(course?.courseScore) || '-',
   course_name: course?.courseName,
   created: moment(course?.createdAt).format('YYYY-DD-MM HH:mm') || '-',
   last_modified: moment(course?.updatedAt).format('YYYY-DD-MM HH:mm') || '-',
   available: course?.usersAccessCount || 0,
   company: course?.companyName,
   rating_from_users: 0,
   tags: course?.tags || null,
   image: course?.courseImageUrl || ''
});

export const coursesProgressMap = (course, idx) => ({
   ...course,
   course_name: course?.courseName || '-',
   available: course?.activeUsersWithAccess || 0,
   ongoing: course?.usersStartedCourse || 0,
   not_started: course?.usersNotStartedCourse || 0,
   failed: course?.usersFailedCourse || 0,
   completed: course?.usersCompletedCourse || 0,
});

export const certificatesMap = (cert, idx) => ({
   certificate_id: cert?.certificateSerialNumber || '-',
   course: cert?.courseName,
   company_name: cert?.companyName || '-',
   student_amount: cert?.studentAmount, 
   percentage: isNaN(cert?.result) ? '0' : cert?.result || '-'
});

export const companiesMap = (company, idx) => ({
   company_name: company?.companyName || '-',
   team_name: company?.teamNames || '-', 
   user: `${company?.studentFirstName || '-'} ${company?.studentLastName || ''}`, 
   user_score: Math.round(company?.companyUserScore) || '-',
});

export const mapStatisticsUserCourses = (course, idx) => ({
   ...course,
   course_name: course?.courseName || '-',
   user_score: `${course?.courseUserScore || 0 } / 100`, 
   progress: course?.progress || '-',
   available_from: course?.lastAccessAt ? moment(course?.createdAt).format('YYYY-DD-MM HH:mm') : '-',
   first_visit: course?.firstAccessAt ? moment(course?.createdAt).format('YYYY-DD-MM HH:mm') : '-',
});

export const integrationMap = (item, idx) => ({
   user: `${item?.userFirstName || '-'} ${item?.userLastName || ''}`, 
   team_name: item?.teamNames || '-', 
   company: item?.companyName || '-',
   assigned_courses: item?.availableCoursesCount || '0',
   completed_courses: item?.completedCoursesCount || '0',
   integration: Math.round(item?.integrationPercentage) || '0',
});

export const statisticsUsersMap = (item, idx) => ({
   ...item,
   user: item?.name || '-', 
   team_name: item?.teamNames || '-', 
   courses: item?.courseNames || '-',
   user_score: item?.companyUserScore ? `${(+item.companyUserScore).toFixed()}/100` : '0/100',
   integration: Math.round(item?.integrationPercentage) || '0',
   progress: Math.round(item?.courseProgress) || '0',
   registration: item?.registeredAt ? moment(item?.registratedAt).format('YYYY-DD-MM HH:mm') : '-',
   first_online: item?.firstLogin ? moment(item?.firstOnline).format('YYYY-DD-MM HH:mm') : '-',
   last_online: item?.lastActivity ? moment(item?.lastActivity).format('YYYY-DD-MM HH:mm') : '-',
   available_from: item?.availableFrom ? moment(item?.availableFrom).format('YYYY-DD-MM HH:mm') : '-',
   last_visit: item?.lastVisit ? moment(item?.lastVisit).format('YYYY-DD-MM HH:mm') : '-',
});