import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styles from './styles.module.scss'
import avatar from "../../assets/images/avatar.svg";
import {useSelector} from "react-redux";
import {pathList} from "../../routes/path";
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { DEFAULT_DATA_LIMIT, ROLE_ID_STUDENT, NOTIFICATION_API_CALL_INTERVAL, ROLE_ID_SUPER_ADMIN, allowedStudentPages, allowedStudentPathsStartsWith } from '../../constants/user';

import { DEFAULT_LANG_LOCALE, DEFAULT_TEXT, returnTranslation } from '../../constants/languages';
import { ALL_LANGUAGES_LIST } from '../../constants/languages';
import { transformRoleText } from '../../utils/rolesHelper';
import { setUserAsAdmin } from "../../store/actions/sidebar";
import useHandleModal from "../../hooks/dom/useHandleModal";
import useConfirm from "../../hooks/useConfirm";
import NotificationPanel from '../NotificationPanel/NotificationPanel';
import NotificationsApi from '../../utils/api/NotificationsApi';
import TranslationApi from '../../utils/api/TranslationApi';
import { useDispatch } from 'react-redux';
import { setNotificationsInfo } from '../../store/actions/notifications';
import sound from '../../assets/notification.mp3';
import { toast } from "react-toastify";
import ToastMsg from "../ToastMsg/ToastMsg";
import { defineNotificationColor, extractScoreAndLink } from '../../constants/notifications';
import ConfirmModal from '../Modals/ConfirmModal/ConfirmModal';
import useUserRole from '../../hooks/useUserRole';
import Button from '../Button/Button';
import CustomSwitch from '../CustomSwitch/CustomSwitch';
import { localStorageGet, localStorageRemove } from '../../utils/localStorage';
import Cookies from 'js-cookie';
import cn from 'classnames';

import { setCompanyTranslation, setUserLanguage, setChosenLanguage, setInfoLoading } from '../../store/actions/sidebar';
import ListLoader from '../ListLoader/ListLoader';
import UserApi from '../../utils/api/UserApi';

const Header = () => {
    const userState = useSelector(state => state.user);
    const notificationAmount = useSelector(state => state?.notifications?.info?.notificationAmount);
    const notRedNotifications = useSelector(state => state?.notifications?.info?.notRedNotifications);
    const observer = useRef(null);
    const [theme, setTheme] = useState('dark-mode');
    const isSideBarFixed = useSelector(state => state.sidebar.isFixed);
    const asStudent = useSelector(state => state.sidebar.isUserAsStudent);
    const muted = useSelector(state => state.notifications.muted);

    const confirmDeleteModal = useHandleModal();
    const notificationModal = useHandleModal();
    const [isNewNotification, setIsNewNotification] = useState(false) 
    const [isLangsListOpen, setIsLangsListOpen] = useState(false) 

    const companyName = useSelector((state) => state.sidebar.companyName);
    const currentCompanyId = useSelector((state) => state.sidebar.companyId);
    const availableLanguages = useSelector((state) => state.sidebar.availableLanguages);
    const languagesLoading = useSelector((state) => state.sidebar.loading);
    const chosenLanguage = useSelector((state) => state.sidebar.chosenLanguage);
    const userLanguage = useSelector((state) => state.sidebar.chosenLanguage);
    const translations = useSelector((state) => state.sidebar.translations);
    
    const { userRoleId, userRoleName } = useUserRole(currentCompanyId);

    const params = useParams()
    const location = useLocation();

   const [allNotifications, setAllNotifications] = useState([]);
   const [notifications, setNotifications] = useState([]);
   const [selectedNotifications, setSelectedNotifications] = useState([]);
   const [currentPage, setCurrentPage] = useState(1);
   const [isLoading, setIsLoading] = useState(false);
   const [isDeleteLoading, setIsDeleteLoading] = useState(false);
   const [allDataLoaded, setAllDataLoaded] = useState(false);

   const [sortFilter, setSortFilter] = useState(null);
   const [readFilter, setReadFilter] = useState({name: 'All', label: null});
   const [dateFilter, setDateFilter] = useState('');
   const [roleFilter, setRoleFilter] = useState({});
   const [selectFilter, setSelectFilter] = useState(null);

   const { handleLinkClick, redirectToPage, confirmModal } = useConfirm();

   const dispatch = useDispatch();

   const abortController = new AbortController();
   const signal = abortController.signal;

   const returnToYourAccount = () => {
      localStorageRemove('impersonateId');
      localStorageRemove('isUserAsStudent');
      window.location.reload();
   } 

   const getTranslations = async (lang) => {
      const res = await new TranslationApi().getTranslations({
         companyId: currentCompanyId,
         locale: lang?.code ? lang?.code : userLanguage?.code,
      });

      if (res?.success?.data) {
         dispatch(setCompanyTranslation(res?.success?.data));
      }
   };

   useEffect(()=> {
      if(!translations?.length && currentCompanyId) {
         getTranslations();
      }
   }, [currentCompanyId])

   const getNotifications = (page, allDataLoadedOnFilter) => {
      if ((allDataLoaded && !allDataLoadedOnFilter) || isLoading) return;
      setIsLoading(true);

      new NotificationsApi()
         .getYourNotifications(DEFAULT_DATA_LIMIT, page ? page : currentPage, sortFilter?.label, dateFilter, readFilter?.label, roleFilter?.roleId, signal)
         .then((res) => {
            if (res?.success?.data) {
               if(res?.success?.code === 204) {
                  setAllDataLoaded(true);
               }

               setNotifications((prevData) => {
                  const newItems = res?.success?.data;
                  const uniqueItems = newItems.filter(item => !prevData.some(existingItem => existingItem.id === item.id));
                  return [...prevData, ...uniqueItems];
                });
               setCurrentPage((prevPage) => prevPage + 1);
            } else {
               // Stop pushing data if there's no data in the request
               setAllDataLoaded(true);
            }
         })
         .finally(() => {
            setIsLoading(false);
         });
   };

   const lastElementRef = useCallback(
      (node) => {
         if (isLoading || allDataLoaded) return;
         if (observer.current) observer.current.disconnect();

         observer.current = new IntersectionObserver((entries) => {
            if (entries[0]?.isIntersecting) {
               getNotifications();
            }
         });

         if (node) observer.current.observe(node);
      },
      [isLoading, allDataLoaded]
   );

   const updateNewNotifications = (res) => {
      setNotifications((prevData) => {
         const newItems = res?.success?.data;
         const uniqueItems = newItems.filter(item => !prevData.some(existingItem => existingItem.id === item.id));
         return [...uniqueItems, ...prevData];
       });
   }

   const getNotRedNotifications = async () => {
      const res = await new NotificationsApi().getYourNotRedNotifications(1, false, signal);

      if (res?.success?.data) {
         const soundToPlay = new Audio(sound)
         const { cleanedMessage } = extractScoreAndLink(res?.success?.data?.[0]?.message)

         const createdAt = new Date(res?.success?.data?.[0]?.createdAt);
         const now = new Date();
         
         const isWithin30Minutes =
            Math.abs(now - createdAt) < NOTIFICATION_API_CALL_INTERVAL;
            
         if(notificationAmount && res?.success?.data?.length > notificationAmount && isWithin30Minutes) {
            toast(<ToastMsg text={cleanedMessage} color={defineNotificationColor(userRoleName)}/>);
            if(!muted) {
               soundToPlay.play();
               updateNewNotifications(res);
            }
         } else if(!notificationAmount && res?.success?.data?.length && isWithin30Minutes) {
            toast(<ToastMsg text={cleanedMessage} color={defineNotificationColor(userRoleName)}/>);
            if(!muted) {
               soundToPlay.play();
               updateNewNotifications(res);
            }
         }
         else {
            soundToPlay.pause()
         }

         dispatch(setNotificationsInfo({
            notificationAmount: res?.success?.totalCount,
            notRedNotifications: res?.success?.data
         }));
      }
   };

   const getAllNotifications = async () => {
      const res = await new NotificationsApi().getYourAllNotifications(signal);

      if (res?.success?.data) {
         setAllNotifications(res?.success?.data);
      }
   };

   useEffect(()=> {
      if(notificationModal?.isActive) {
         refetchOnChange();
        if(!allNotifications?.length) getAllNotifications();
      }
      
   }, [notificationModal?.isActive])

   const refetchOnChange = () => {
      setAllDataLoaded(false);
      setNotifications([]);
      getNotifications(1, true);
      setCurrentPage(1);
   }

    useEffect(() => {
      const notificationInterval = setInterval(() => {
         getNotRedNotifications();
       }, NOTIFICATION_API_CALL_INTERVAL);
 
       return () => {
         clearInterval(notificationInterval);
       };
    }, [notificationAmount]);

    useEffect(() => {
      const hasLoaded = Cookies.get('notificationsLoaded');
      
      if (!hasLoaded) {
         getNotRedNotifications().then(() => {
            Cookies.set('notificationsLoaded', 'true', { expires: 3 / 86400 }); // 3 seconds, to prevent refetch on quick rerender.
         });
      }

       return () => {
         abortController.abort();
         setNotifications([]);
         setAllNotifications([]);
       };
    }, []);

   useEffect(()=> {
      if(notificationModal?.isActive) refetchOnChange();
   }, [sortFilter, dateFilter, readFilter, roleFilter])

   const deleteUserNotifications = async () => {
      setIsDeleteLoading(true);
      const selectedToDelete = selectedNotifications?.map(item => item.id);
      const allToDelete = allNotifications?.map(item => item.id);

      const data = {
         id: selectFilter?.label === 'delete_all' ? allToDelete : selectedToDelete
       }

      const res = await new NotificationsApi().deleteNotifications(data);

      if(res?.success?.message) {
         toast(<ToastMsg text={res?.success?.message} />)
      }

      confirmDeleteModal.close();
      setSelectFilter(null);
      refetchOnChange();
      setSelectedNotifications([]);
      setIsDeleteLoading(false);
   }

    const isValidPathname = (pathname) => {
      // not ":id" not "numbers" and not ''
      return !/^(:id|\d+|)$/.test(pathname);
    };
    
    const pathnames = location.pathname.split('/').filter((pathname) => isValidPathname(pathname));
    const navigate = useNavigate();

    const role = transformRoleText(userRoleName, companyName) 

    const name = userState?.info?.firstName || '';
    const avatarLink = userState?.info?.avatar ? `${userState?.info?.avatar}` : avatar;

    useEffect(()=> {
      if(selectFilter?.label === "delete_all") {
         confirmDeleteModal.open();
      }
    }, [selectFilter])
  
   const onSelectNotifications = (e, notification) => {
      e.stopPropagation();
      if (selectedNotifications?.find(item => item?.id === notification?.id)) setSelectedNotifications(selectedNotifications?.filter(item => item?.id !== notification?.id))
      else setSelectedNotifications([...selectedNotifications, notification])
  }

    const renderBreadcrumbs = () => {
      return pathnames.map((pathname, index) => {
        let routePath = `/${pathnames.slice(0, index + 1).join('/')}`;
        let displayText = pathname.replace(/_/g, ' ');
        displayText = displayText.replace(/Lesson\s+/i, '');
        const lastShownEl = pathnames.length - 1;

        if(routePath.includes('settings') || routePath.includes('edit')) {
          const url = window.location.href;
          const segments = url?.split('edit/');

          if (segments.length >= 2) {
            const numberAfterEdit = segments[1].split('/')[0];
            routePath += `/${numberAfterEdit}`;
          } 
        }

        if(routePath.includes('profile') || routePath.includes('team')) {
          const url = window.location.href;
          const segments = url?.split('team/');

          if (segments.length >= 2) {
            const numberAfterEdit = segments[1].split('/')[0];
            routePath += `/${numberAfterEdit}`;
          } 
        }

        if (routePath === '/courses/folder') {
            const folderLink = window.location.href?.split('topic')?.[0];
            const folderSecondLink = window.location.href?.split('edit')?.[0];
            routePath = folderLink.endsWith('/') ? folderLink?.slice(0, -1) : folderSecondLink?.slice(0, -1);
         }

        if (routePath === '/courses/folder/topic') {
            const topicLink = window.location.href?.split('edit')?.[0];
            routePath = topicLink.endsWith('/') ? topicLink.slice(0, -1) : topicLink;
         }

        if (routePath.toLowerCase().includes('modal') || routePath.toLowerCase().includes('default_lang')) {
         // Exclude breadcrumb if the route includes "moda"
         return null;
       }

        return (
          <li key={index} className={styles.breadcrumbs_item}>
            <Link onClick={(e)=> handleLinkClick(e, index !== pathnames.length - 1 ? routePath : '')} className={`${styles.breadcrumbs_link} ${index === lastShownEl && styles.active_link} `} to={index !== pathnames.length - 1 ? routePath : ''}>{returnTranslation(translations, displayText)}</Link>
          </li>
        );
      });
    };  

    const changeUserLanguage = async (code) => {
      if(code === chosenLanguage?.code) return;

      const foundLang = ALL_LANGUAGES_LIST?.find((l) => l.code === code) || DEFAULT_LANG_LOCALE;
      dispatch(setInfoLoading(true));

      const data = {
         new_locale: foundLang?.code
      }

      const res = await new UserApi().changeYourUserData(data)

      if(res?.success) {
         dispatch(setChosenLanguage(foundLang));
         await getTranslations(foundLang);
      }
      
      if(res?.error?.message) {
         toast(<ToastMsg isError text={res?.error?.message}/>)
      }

      dispatch(setInfoLoading(false));
    }
 
    const isAllowedPage = allowedStudentPages.includes(location.pathname);
    const isAllowedPrefix = allowedStudentPathsStartsWith.some((prefix) =>
      location.pathname.startsWith(prefix)
    );

    const isRestrictedPage = asStudent && userRoleId && !isAllowedPage && !isAllowedPrefix;

   useEffect(() => {
      const isAllowedPage = allowedStudentPages.includes(location.pathname);
      const isAllowedPrefix = allowedStudentPathsStartsWith.some((prefix) =>
        location.pathname.startsWith(prefix)
      );
  
      const isRestrictedPage = asStudent && userRoleId && !isAllowedPage && !isAllowedPrefix;
  
      if (isRestrictedPage) {
        navigate("/home");
      }
    }, [asStudent, userRoleId, location.pathname]);

    const studentText = returnTranslation(translations, DEFAULT_TEXT.common?.student)
    const checkRole = returnTranslation(translations, DEFAULT_TEXT.common?.checkRole)
    const userTemplateName = returnTranslation(translations, DEFAULT_TEXT.common?.user)
    const returnToYourAccountText = returnTranslation(translations, DEFAULT_TEXT.common?.returnToYourAccount)
    const areYouSure = returnTranslation(translations, DEFAULT_TEXT.modals?.areYouSure)
    const uploadingFilesWillBeStopped = returnTranslation(translations, DEFAULT_TEXT.modals?.uploadingFilesWillBeStopped)
    const confirmText = returnTranslation(translations, DEFAULT_TEXT.comman?.confirm)
    const deleteText = returnTranslation(translations, DEFAULT_TEXT.comman?.delete)
   
    return (
        <header className={`${isSideBarFixed && styles.sidebar_fixed} ${styles.header}`}>
            <ul className={styles.breadcrumbs}>
               <li className={styles.breadcrumbs_item}>
                  <Link className={`${styles.breadcrumbs_link} ${styles.home}`} to="/home">Home</Link>
               </li>
               {renderBreadcrumbs()}
            </ul>

            {userRoleId && userRoleId !== ROLE_ID_STUDENT && 
               <div className={styles.switch}>
                  <CustomSwitch 
                     leftText={transformRoleText(userRoleName, companyName)} 
                     rightText={'Student'}
                     isRight={asStudent ? true : false} 
                     setSwitch={(value) => {
                        dispatch(setUserAsAdmin(value));
                     }}
                     isHeader
                  />
               </div>
            }

            {/* COMMENT NOT WORKING STUFF */}

            {/* <form className={styles.header_search}>
                <input className={styles.header_search_input} type="text" id="header-search"
                       placeholder="Search"/>
                <label className={styles.header_search_label} htmlFor="header-search">Search</label>
            </form>
            <div className={styles.theme_mode}>
                <input className={`${styles.theme_mode_radio} ${styles.light}`} id="light-mode"
                       name="theme-mode"
                       type="radio"
                       checked={theme === 'light-mode'}
                       onChange={()=> setTheme('light-mode')}/>
                <label className={`${styles.theme_mode_label} ${styles.light}`} htmlFor="light-mode">Light
                    mode</label>
                <input className={`${styles.theme_mode_radio} ${styles.dark}`} id="dark-mode"
                       name="theme-mode" type="radio"
                       checked={theme === 'dark-mode'} onChange={()=> setTheme('dark-mode')}/>
                <label className={`${styles.theme_mode_label} ${styles.dark}`} htmlFor="dark-mode">Dark
                    mode</label>
            </div> */}
            <div
                onMouseOver={()=> setIsLangsListOpen(true)}
                onMouseLeave={()=> setIsLangsListOpen(false)}
                className={styles.language}>
                <button className={`${styles.button_language} ${styles.current}`} type="button">
                     <>
                        { languagesLoading && isLangsListOpen &&
                           <div className={styles.loader}>
                              <ListLoader size={"small"}/>
                           </div>
                        }
                        { chosenLanguage?.displayed_code }
                     </>                 
               </button>
                <ul className={cn(styles.language_list, availableLanguages?.length > 3 ? styles.scroll : '')}>
                {availableLanguages?.map((lang) => (
                     <li key={lang} className={styles.language_list_item}>
                        <button 
                           onClick={()=> changeUserLanguage(lang)} 
                           className={styles.button_language} type="button"
                        >
                           {ALL_LANGUAGES_LIST?.find((l) => l.code === lang)?.displayed_code || lang}
                        </button>
                     </li>
                  ))}
                </ul>
            </div>
           
            <button  onClick={notificationModal.open} className={styles.notification_wrapper}>
               <div className={styles.button_notification} type="button">
               {!!notificationAmount && notificationAmount > 0 && 
                  <span>{notificationAmount}</span>
               }
                  </div> 
            </button>

            <button className={styles.short_profile} onClick={(e)=> handleLinkClick(e, '/profile/1')} type="button">
               <span className={styles.short_profile_info}>
                  <span className={styles.short_profile_name}>{name || userTemplateName}</span>
                  <span className={styles.short_profile_speciality}>{role ? role : `${checkRole}...`}
                  {/* {asStudent && role && userRoleId !== ROLE_ID_STUDENT &&
                     <span className={styles.as_student_mark}> {`(${studentText})`}</span>
                  } */}
                  </span>
                
               </span>
               <span className={styles.avatar_wrap}>
                  <img className={styles.avatar_wrap_image} src={avatarLink} alt="" width="40" height="40"/>
                  <span className={`${styles.profile_status} ${styles.online}`} title="online"></span>
               </span>
            </button>

            <NotificationPanel
               onClose={notificationModal.close}
               isOpen={notificationModal.isActive}
               onConfirm={notificationModal.close}
               notifications={notifications}
               setNotifications={setNotifications}
               lastElementRef={lastElementRef}
               sortFilter={sortFilter}
               setSortFilter={setSortFilter}
               dateFilter={dateFilter}
               setDateFilter={setDateFilter}
               readFilter={readFilter}
               setReadFilter={setReadFilter}
               selectFilter={selectFilter}
               setSelectFilter={setSelectFilter}
               notRedNotifications={allNotifications?.filter((n)=> !n?.isArchived)}
               isLoading={isLoading}
               onSelectNotifications={onSelectNotifications}
               selectedNotifications={selectedNotifications}
               confirmDeleteModal={confirmDeleteModal}
               isDeleteLoading={isDeleteLoading}
               roleFilter={roleFilter}
               setRoleFilter={setRoleFilter}
            />

            <ConfirmModal
               isRemove
               confirmButtonText={deleteText}
               onConfirm={deleteUserNotifications}
               onClose={confirmDeleteModal.close}
               isOpen={confirmDeleteModal.isActive}
               isLoading={isDeleteLoading}
               title={areYouSure}
            /> 

         {localStorageGet('impersonateId') && 
            <div className={styles.return}>
               <Button onClick={returnToYourAccount} title={returnToYourAccountText} />
            </div>
         }

         <ConfirmModal
            confirmButtonText={confirmText}
            isOpen={confirmModal.isActive}
            onClose={confirmModal.close}
            isOpen={confirmModal.isActive}
            onConfirm={redirectToPage}
            maxHeight={'310px'}
            title={areYouSure}
            subtitle={uploadingFilesWillBeStopped}
         />
        </header>
    );
};

export default Header;