import dots from "../../../assets/images/symbols/three_dots_white.svg";
import CustomTooltip from "../../../components/CustomTooltip/CustomTooltip";
import Checkbox from "../../../components/Checkbox/Checkbox";
import useOptions from "../../../hooks/useOptions";
import { v4 as uuidv4 } from "uuid";
import styles from "./styles.module.scss";

import cn from "classnames";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ROLE_ID_SUPER_ADMIN } from "../../../constants/user";
import useUserRole from "../../../hooks/useUserRole";

const UsersBodyItem = ({
   row,
   columns,
   index,
   onRowClick,
   tableFor,
   unblockUser,
   openConfirmModal,
   openConfirmModalUnblock,
   openConfirmModalDelete,
   openChangeRoleModal,
   lastUserRef,
   selectedUsers,
   isRowsClickable,
   t,
}) => {
   const { dropdownRef, dotsRef, isOptionsOpen, closeOptions, toggleOptions } =
      useOptions();
      
   const currentCompanyId = useSelector(state => state.sidebar.companyId);
   const { userRoleId } = useUserRole(currentCompanyId);

   const navigate = useNavigate();

   const redirectToUserProfileAdminView = (id) => {
      window.open(`/users/profile/${id}`, '_blank');
   };

   return (
      <tr
         ref={lastUserRef}
         onClick={() => onRowClick(row)}
         key={`row-${index}`}
         className={cn(
            index % 2 === 0 ? styles.rowOdd : "",
            tableFor === "teams" || isRowsClickable ? styles.link : ""
         )}
      >
         {columns.map((column, columnIndex) => (
            <td key={`cell-${index}-${columnIndex}`}>
               <div 
                  className={cn(
                            styles.cellContent, column === "checkbox" ? styles.cellContentCheckbox : '',
                        )}
               >
                  {column === "checkbox" && (
                     <div className={styles.checkbox}>
                        <Checkbox
                           isChecked={selectedUsers?.some(
                              (sItem) => sItem?.id === row?.id
                           )}
                           isGrey
                        />
                     </div>
                  )}
                  <CustomTooltip
                     className={
                        row[column] === "waiting"
                           ? styles.yellowText
                           : row[column] === "accepted"
                           ? styles.greenText
                           : row[column] === "declined"
                           ? styles.redText
                           : ""
                     }
                     id={row[column]}
                     limit={20}
                     text={row[column] !== "waiting" ? row[column] : ""}
                  />
               </div>
            </td>
         ))}

         {tableFor !== "teams" && (
            <td className={styles.option_wrapper}>
               <img
                  ref={dotsRef}
                  onClick={() => toggleOptions()}
                  src={dots}
                  alt={"options"}
               />
               {isOptionsOpen && tableFor === "blocked" && (
                  <div ref={dropdownRef} className={styles.dropdown}>
                     <p
                        className={styles.options}
                        onClick={() => redirectToUserProfileAdminView(row?.id)}
                     >
                        {t?.profile}
                     </p>
                     <p
                        className={styles.options}
                        onClick={() => {
                           closeOptions();
                           if (openConfirmModalUnblock) openConfirmModalUnblock(row.id);
                        }}
                     >
                        {t?.unblockUser}
                     </p>
                     <p
                        className={styles.options}
                        onClick={() => {
                           closeOptions();
                           if (openConfirmModalDelete) openConfirmModalDelete(row.id);
                        }}
                     >
                        {t?.delete}
                     </p>
                  </div>
               )}
               {isOptionsOpen &&
                  (tableFor === "students" || tableFor === "roles") && (
                     <div ref={dropdownRef} className={styles.dropdown}>
                        <p
                           className={styles.options}
                           onClick={() =>
                              redirectToUserProfileAdminView(row?.id)
                           }
                        >
                           {t?.profile}
                        </p>

                        {userRoleId === ROLE_ID_SUPER_ADMIN && 
                            <p
                              className={styles.options}
                              onClick={() => {
                                 closeOptions();
                                 if (openChangeRoleModal) openChangeRoleModal(row.id);
                              }}
                           >
                             {t?.changeRole}
                           </p>
                        }
                       
                        <p
                           className={styles.options}
                           onClick={() => {
                              closeOptions();
                              if (openConfirmModal) openConfirmModal(row.id);
                           }}
                        >
                           {t?.blockUser}
                        </p>
                     </div>
                  )}
            </td>
         )}
      </tr>
   );
};

export default UsersBodyItem;
