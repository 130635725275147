import React, { useEffect, useState } from "react";
import MainLayout from "../../layouts/MainLayout/MainLayout";
import KnowledgeCheckAPI from "../../utils/api/KnowledgeCheckAPI";
import searchImg from "../../assets/images/symbols/Search.svg";
import UsersTestsTable from "./UsersTestsTable/UsersTestsTable";

import styles from "./styles.module.scss";
import cn from "classnames";
import { USERS_TESTS_COLUMN, USER_TESTS_COLUMN, USER_TESTS_COLUMN_CHECKED, USERS_TESTS_COLUMN_CHECKED } from "../../constants/tests";
import KnowledgeSubheader from "./KnowledgeSubheader/KnowledgeSubheader";
import { useSelector } from "react-redux";
import { DEFAULT_DATA_LIMIT, PART_OF_ADMIN_NAME, PART_OF_CURATOR_NAME, PART_OF_MENTOR_NAME, ROLE_ID_STUDENT, ROLE_ID_SUPER_ADMIN } from "../../constants/user";
import { testsMap } from "../../utils/coursesHelper";
import Loader from "../../components/Loader/Loader";
import useUserRole from "../../hooks/useUserRole";
import { DEFAULT_DEBOUNCE_DELAY } from "../../constants/statistics";
import debounce from 'lodash/debounce';
import useIntersectionObserver from "../../hooks/useIntersectionObserver";
import useGetActiveUsersOfCompany from "../../hooks/api/useGetActiveUsersOfCompany";
import useFindTranslations from "../../hooks/useFindTranlsations";

// This is the front page of knowledge check without actual check
// We redirect to ActualKnowledgeCheck page when we choose a student from the table
// This is also a page for student as well as fo admins.

const KnowledgeCheckPage = () => {
   const [searchedValue, setSearchedValue] = useState("");
   const [activeTab, setActiveTab] = useState("awaiting_checking");
   const [activeFilter, setActiveFilter] = useState({status: null});

   const [tests, setTests] = useState([]);
   const [testsPage, setTestsPage] = useState(1);
   const [testsLoading, setTestsLoading] = useState(true);
   const [totalTests, setTotalTests] = useState(0);

   const t = useFindTranslations();

   //  for filtered students
   const [selectedStudents, setSelectedStudents] = useState([]);
   const [dateFilter, setDateFilter] = useState(null);
   const [frontTimeFilterValue, setFrontTimeFilterValue] = useState(null);

   const [studentsConfirmed, setStudentsConfirmed] = useState(false);

   const onConfirmStudentFilter = () => {
      setStudentsConfirmed(!studentsConfirmed)
   }

   const companyId = useSelector(state => state.sidebar.companyId);
   const { userRoleId, userRoleName } = useUserRole(companyId);
   
   const userInfo = useSelector(state => state?.user?.info);
   const isUserAsStudent = useSelector(state => state.sidebar.isUserAsStudent);

   const handleTabChange = (tab) => {
      setActiveTab(tab);
   };

   const { setCurrentPage, totalUsers, 
      userList: studentsList, 
      setUserList: setStudentsList, 
      isUsersLoading: isStudentLoading, 
      searchedValue: studentsSearchedValue, 
      setSearchedValue: setStudentsSearchedValue 
   } = useGetActiveUsersOfCompany(companyId);

   const lastStudentRef = useIntersectionObserver(
   () => {
   if (!isStudentLoading && studentsList?.length < totalUsers) {
      if(studentsList?.length === 0) {
         setCurrentPage(1);
      } else {
         setCurrentPage((prev) => prev + 1);
      }
   }
   },
   [isStudentLoading, totalUsers]
   );

   const fetchTests = async (page, limit = null) => {
      if(!companyId || !userRoleId) return;

      // const isCurator = userRoleName?.includes(PART_OF_CURATOR_NAME); TODO if needed show tests of courses where you are curator.
      const isMentor = userRoleName?.includes(PART_OF_MENTOR_NAME);
   
      const isShowRelatedToYouTests = userRoleId !== ROLE_ID_SUPER_ADMIN && userRoleId !== ROLE_ID_STUDENT && !isUserAsStudent && !isMentor;

      const isYourTests = userRoleId === ROLE_ID_STUDENT || isUserAsStudent;
      const isAdminAsStudent = userRoleId !== ROLE_ID_SUPER_ADMIN && isUserAsStudent;
      const usersIds = selectedStudents?.map(item => item?.id);

      setTestsLoading(true);
      const res = await new KnowledgeCheckAPI().getUsersTest(limit ? limit : DEFAULT_DATA_LIMIT, page ? page : testsPage, companyId, activeTab === 'awaiting_checking' ? 0 : 1, activeFilter?.status, searchedValue, isShowRelatedToYouTests, isAdminAsStudent, isYourTests, isMentor, usersIds, dateFilter);

      if(res?.success?.data) {
         if(page) {
            setTests(res?.success?.data);
         } else {
            setTests((prev)=> [...prev, ...res?.success?.data]);
         }

         setTotalTests(res?.success?.totalCount)
      }

      if(res?.success?.data?.length === 0) {
         setTestsPage(1);
      }

      if(res?.error?.message && page) {
         setTests([]);
      }

      setTestsLoading(false);
   }

   const lastTestRef = useIntersectionObserver(
      () => {
        if (!testsLoading && tests?.length < totalTests) {
          if(tests?.length === 0) {
            setTestsPage(1);
          } else {
            setTestsPage((prev) => prev + 1);
          }
        }
      },
      [testsLoading, totalTests]
    );

   useEffect(()=> {
      if(testsPage > 1 && tests?.length <= totalTests) {
         fetchTests();
      }
   }, [testsPage, userRoleId])

   const debouncedFetchTests = debounce(()=> fetchTests(1), DEFAULT_DEBOUNCE_DELAY); 

   useEffect(()=> {
     if(companyId && userRoleId) {
         setTestsPage(1);
         debouncedFetchTests();
     }
      return () => debouncedFetchTests.cancel();
   }, [searchedValue, activeTab, activeFilter, userRoleId, studentsConfirmed, dateFilter, isUserAsStudent])

   useEffect(()=> {
      setTests([]);
      setTestsLoading(true);
      setSearchedValue('');
      setActiveFilter({status: null})
   }, [activeTab])

   const columnsCondition = (userRoleId && userRoleId !== ROLE_ID_STUDENT && !isUserAsStudent) || userRoleId === ROLE_ID_SUPER_ADMIN ? USERS_TESTS_COLUMN : USER_TESTS_COLUMN;
   
   const columnsConditionChecked = (userRoleId && userRoleId !== ROLE_ID_STUDENT && !isUserAsStudent) || userRoleId === ROLE_ID_SUPER_ADMIN ? USERS_TESTS_COLUMN_CHECKED : USER_TESTS_COLUMN_CHECKED;

   const mappedTests = tests?.map(testsMap)
 
   const generateCsvData = () => {
      return [
         activeTab === 'awaiting_checking' ? columnsCondition:  columnsConditionChecked,
         ...mappedTests?.map(({ student, course_name, test_name, end_date, pass_time, result }) => [
            student, course_name, test_name, end_date, pass_time, result
          ])
      ];
    };

   return (
      <MainLayout darkBg>
         <main className={styles.knowledge_check_front_page}>
            <div className={styles.tab_navigation}>
               <button
                  className={cn(
                     styles.tab,
                     activeTab === "awaiting_checking" && styles.activeTab
                  )}
                  onClick={() => handleTabChange("awaiting_checking")}
               >
                  {t?.awaitingChecking}
               </button>
               
               <button
                  className={cn(
                     styles.tab,
                     activeTab === "checked" && styles.activeTab
                  )}
                  onClick={() => handleTabChange("checked")}
               >
                  {t?.checked}
               </button>
            </div>

         {testsLoading && 
            <div className="default_loader_wrapper">
               <Loader size="small" />
            </div>
         }
    
         {activeTab === "awaiting_checking" && (
               <>
                  <KnowledgeSubheader
                     setSearchedValue={setSearchedValue}
                     searchedValue={searchedValue}
                     csvData={generateCsvData()}
                     fetchTests={fetchTests}
                     isDataLoading={testsLoading}
                     students={studentsList}
                     studentsSearchedValue={studentsSearchedValue}
                     setStudentsSearchedValue={setStudentsSearchedValue}
                     selectedStudents={selectedStudents}
                     setSelectedStudents={setSelectedStudents}
                     lastStudentRef={lastStudentRef}
                     onConfirmStudentFilter={onConfirmStudentFilter}
                     dateFilter={dateFilter}
                     setDateFilter={setDateFilter}
                     frontTimeFilterValue={frontTimeFilterValue}
                     setFrontTimeFilterValue={setFrontTimeFilterValue}
                  />

                  <UsersTestsTable
                     key={activeTab}
                     isTestsLoading={testsLoading}
                     data={mappedTests}
                     columns={columnsCondition}
                     userRoleId={userRoleId}
                     lastTestRef={lastTestRef}
                     t={t}
                  />
               </>
            )}
            {activeTab === "checked" && (
               <>
                  <KnowledgeSubheader
                     setSearchedValue={setSearchedValue}
                     searchedValue={searchedValue}
                     csvData={generateCsvData()}
                     checked
                     activeFilter={activeFilter}
                     fetchTests={fetchTests}
                     isDataLoading={testsLoading}
                     setActiveFilter={setActiveFilter}
                     students={studentsList}
                     studentsSearchedValue={studentsSearchedValue}
                     setStudentsSearchedValue={setStudentsSearchedValue}
                     selectedStudents={selectedStudents}
                     setSelectedStudents={setSelectedStudents}
                     lastStudentRef={lastStudentRef}
                     onConfirmStudentFilter={onConfirmStudentFilter}
                     dateFilter={dateFilter}
                     setDateFilter={setDateFilter}
                     frontTimeFilterValue={frontTimeFilterValue}
                     setFrontTimeFilterValue={setFrontTimeFilterValue}
                     t={t}
                  />

                  <UsersTestsTable
                     key={activeTab}
                     columns={columnsConditionChecked}
                     userRoleId={userRoleId}
                     isTestsLoading={testsLoading}
                     data={mappedTests}
                     lastTestRef={lastTestRef}
                     t={t}
                  />
               </>
            )}
         </main>
      </MainLayout>
   );
};

export default KnowledgeCheckPage;
