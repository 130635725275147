import React from "react";
import dots from "../../../assets/images/symbols/three_dots_white.svg";
import CustomTooltip from "../../../components/CustomTooltip/CustomTooltip";
import Checkbox from "../../../components/Checkbox/Checkbox";
import useOptions from "../../../hooks/useOptions";
import { v4 as uuidv4 } from "uuid";
import styles from "./styles.module.scss";
import arrow from "../../../assets/images/symbols/Arrow - Down.svg";
import statusActiveImg from "../../../assets/images/symbols/arrow_green.svg";
import statusPausedImg from "../../../assets/images/paused_status.svg";
import statusDeletedImg from "../../../assets/images/deleted_status.svg";

import cn from "classnames";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ROLE_ID_SUPER_ADMIN } from "../../../constants/user";
import { ROLES_COLORS } from "../../../constants/notifications";
import {
   STUDENT_STATUS_ACTIVE,
   STUDENT_STATUS_DELETED,
   STUDENT_STATUS_PAUSED,
} from "../../../constants/courses";
import useUserRole from "../../../hooks/useUserRole";
import ProgressLine from "../../../components/ProgressLine/ProgressLine";
import useFindTranslationsByString from "../../../hooks/useFindTranslationsByString";

const UsersBodyItem = ({
   row,
   columns,
   index,
   tableFor,
   openConfirmModal,
   onOpenStatusModal,
   lastItemRef,
   selectMode,
   onClickRow,
   selectedUserListChangeStatus
}) => {

   const { dropdownRef, dotsRef, isOptionsOpen, closeOptions, toggleOptions } =
      useOptions();

   const t = useFindTranslationsByString();

   const companyId = useSelector(state => state.sidebar.companyId);
   const { userRoleId } = useUserRole(companyId);

   const navigate = useNavigate();

   const redirectToUserProfileAdminView = (id) => {
      window.open(`/users/profile/${id}`, '_blank');
   };

   const defineIcon = (status) => {
      if (status === STUDENT_STATUS_ACTIVE) {
         return statusActiveImg;
      } else if (status === STUDENT_STATUS_PAUSED) {
         return statusPausedImg;
      } else if (status === STUDENT_STATUS_DELETED) {
         return statusDeletedImg;
      } else {
         return statusActiveImg;
      }
   };

   const handleRowClick = () => {
      if (selectMode?.length) {
         onClickRow(row);
      }
   };

   const isChecked = selectedUserListChangeStatus?.some(
     (sItem) => sItem?.id === row?.id
   );

   return (
      <tr
         ref={lastItemRef}
         key={`row-${index}`}
         className={cn(
            index % 2 === 0 ? styles.rowOdd : "", 
            selectMode?.length > 0 ? styles.clickableEl : ''
         )}
         onClick={handleRowClick}
      >
         {columns.map((column, columnIndex) => (
            <React.Fragment key={`cell-${index}-${columnIndex}`}>
               {column === "checkbox" && (
                  <td className={styles.table_data}>
                     <Checkbox
                        isChecked={selectedUserListChangeStatus?.some(
                           (sItem) => sItem?.id === row?.id
                        )}
                        isGrey
                     />
                  </td>
               )}

               {column === "team" && (
                  <td className={styles.table_data}>
                     <div className={styles.table_data_depart}>
                        <CustomTooltip id={row.id} text={row?.team} limit={8} />
                     </div>
                  </td>
               )}
             
               {column !== "checkbox" && column !== "status" && column !== "team" &&  column !== 'progress' && column !== 'role' && (
                  <td>
                     <div className={cn( 
                           styles.cellContent, 
                           row[column] === '-' ? styles.centered : ''
                        )}>
                        <CustomTooltip
                           id={row[column]}
                           limit={12}
                           text={row[column] !== "waiting" ? row[column] : ""}
                        />
                     </div>
                  </td>
               )}

               {column === "status" && (
                  <td className={styles.cellContent}>
                     <div
                        className={styles.actions}
                        onClick={() => onOpenStatusModal(row)}
                     >
                        <img
                           src={defineIcon(row?.status_on_course)}
                           alt="icon"
                        />
                        <img src={arrow} alt="" />
                     </div>
                  </td>
               )}

               {column === "role" && (
                  <td className={cn(styles.cellContent, styles.role)}>
                      <div
                        style={{ backgroundColor: ROLES_COLORS?.find(c => c?.title?.toLowerCase() === row[column]?.toLowerCase())?.color || "#B079F0" }}
                        className={styles.dotM}
                     ></div>
                    <span>{t(row[column])}</span>
                  </td>
               )}

            {column === "progress" && (
               <td className={styles.cellContent}>
                  <ProgressLine progress={row?.progress} />
               </td>
               )
            }
            </React.Fragment>
         ))}

         {tableFor !== "requests" && tableFor !== "teams" && (
            <td className={styles.option_wrapper}>
               <img
                  ref={dotsRef}
                  onClick={(e) => {
                     e.stopPropagation();
                     toggleOptions();
                  }}
                  src={dots}
                  alt={"options"}
               />
              
               {isOptionsOpen &&
                  (tableFor === "students" || tableFor === "roles") && (
                     <div ref={dropdownRef} className={styles.dropdown}>
                        <p
                           className={styles.options}
                           onClick={() =>
                              redirectToUserProfileAdminView(row?.id)
                           }
                        >
                           {t("Profile")}
                        </p>

                        <p
                           className={styles.options}
                           onClick={() => {
                              closeOptions();
                              if (openConfirmModal) openConfirmModal(row.id);
                           }}
                        >
                           {t("Block user")}
                        </p>
                     </div>
                  )}
            </td>
         )}
      </tr>
   );
};

export default UsersBodyItem;
